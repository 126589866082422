/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Garamond";
  font-style: normal;
  font-weight: normal;
  src: local("Garamond"), url("Garamond.ttf") format("ttf");
}

@font-face {
  font-family: "Garamond";
  font-style: normal;
  font-weight: bold;
  src: local("Garamond Bold"), url("Garamond\ Bold\ font.ttf") format("ttf");
}

@font-face {
  font-family: "Garamond";
  font-style: italic;
  font-weight: normal;
  src: local("Garamond"), url("Garamond\ Medium\ Italic.ttf") format("ttf");
}

@font-face {
  font-family: "Garamond";
  font-style: normal;
  font-weight: normal;
  src: local("Garamond"), url("Garamond\ Regular.ttf") format("ttf");
}
