/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Trajan Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Trajan Pro Regular"), url("TrajanPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "Trajan Pro";
  font-style: normal;
  font-weight: bold;
  src: local("Trajan Pro Bold"), url("TrajanPro-Bold.woff") format("woff");
}
