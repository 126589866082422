/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: normal;
  src: local("Cinzel Decorative Regular"), url("CinzelDecorative-Regular.woff") format("woff");
}

@font-face {
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: bold;
  src: local("Cinzel Decorative Bold"), url("CinzelDecorative-Bold.woff") format("woff");
}

@font-face {
  font-family: "Cinzel Decorative";
  font-style: normal;
  font-weight: 900;
  src: local("Cinzel Decorative Black"), url("CinzelDecorative-Black.woff") format("woff");
}
