@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/fonts/bodoni/style.css";
@import "./assets/fonts/cinzel-decorative/style.css";
@import "./assets/fonts/garamond/style.css";
@import "./assets/fonts/theano-didot/style.css";
@import "./assets/fonts/trajan-pro/style.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@layer base {
  .base {
    @apply flex flex-col flex-1 px-8 py-12 mt-12 md:mt-0 md:ml-16;
  }
  .text-gradient-shadow {
    @apply text-transparent relative before:content-[attr(content)] before:absolute before:z-20 before:bg-gradient-to-r before:from-yellow-300 before:via-amber-200 before:!to-yellow-300 before:text-transparent before:bg-clip-text before:left-1/2 before:-translate-x-[50%] before:top-1/2 before:-translate-y-[50%] before:w-full after:content-[attr(content)] after:absolute after:z-10 after:text-shadow after:shadow-black after:left-1/2 after:-translate-x-[50%] after:top-1/2 after:-translate-y-[50%] after:w-full;
  }

  /* Tag selectors for headings of text editor */
  h1 {
    @apply text-2xl lg:text-3xl font-black;
  }
  h2 {
    @apply text-xl lg:text-2xl font-bold;
  }
  h3 {
    @apply text-lg lg:text-xl font-semibold;
  }
  h4 {
    @apply text-sm lg:text-lg font-normal;
  }
  h5 {
    @apply text-sm italic font-black;
  }
  h6 {
    @apply text-sm font-light;
  }

  /* Classes instead of tag selectors to avoid unintended format of text editor contents */
  .h1 {
    @apply text-3xl lg:text-5xl font-black font-trajanPro text-center !leading-normal capitalize;
  }
  .h2 {
    @apply text-2xl lg:text-3xl font-bold font-garamond text-center;
  }
  .h3 {
    @apply text-xl lg:text-2xl font-semibold font-garamond text-center;
  }
}
